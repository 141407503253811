import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import * as iconList from '../icon-list.json';
import { BASE_ICONS_PATH_TOKEN } from '@rhbnb-nx-ws/global-tokens';

@Component({
  selector: 'rhbnb-service-icon-selector',
  templateUrl: './service-icon-selector.component.html',
  styleUrls: ['./service-icon-selector.component.scss'],
})
export class ServiceIconSelectorComponent implements OnInit {
  iconList: any = (iconList as any).default;

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  constructor(
    @Inject(BASE_ICONS_PATH_TOKEN) public iconPath: string,
  ) {}

  ngOnInit(): void {}

  getIconPath(icon: string) {
    return `${this.iconPath}/${icon}.svg`;
  }

  onSelectedChange(value: string) {
    this.value = value;
    this.valueChange.emit(value);
  }
}
