import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  of,
  Subject,
  throwError as observableThrowError,
  from
} from 'rxjs';
import {
  catchError,
  finalize,
  mergeMap,
  shareReplay,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { NotifyService, OAuth2Service, StorageService } from '@rhbnb-nx-ws/services';
import { CookieService } from 'ngx-cookie';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { ROLES, User } from '@rhbnb-nx-ws/domain';
import { NGXLogger } from 'ngx-logger';
import { Location } from '@angular/common';
import { IURLS, OAuth2Config } from '@rhbnb-nx-ws/global-tokens';

import { UserService } from './user.service';
import { PreviousRouteService } from './previous-route-service';
import { ProfileService } from './profile.service';
import { ClientService } from './client.service';
import { NavigationUtilService } from './navigation-util.service';

export class AbstractLoginService<T> {

  private readonly sessionEstablished$$ = new Subject<void>();
  sessionEstablished$ = this.sessionEstablished$$.asObservable();

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected storageService: StorageService,
    protected clientService: ClientService,
    protected notifyService: NotifyService,
    protected translate: TranslocoService,
    protected prs: PreviousRouteService,
    protected router: Router,
    protected location: Location,
    protected profileService: ProfileService,
    protected endpointName: string,
    protected document: Document,
    protected translocoService: TranslocoService,
    protected navigationUtilService: NavigationUtilService,
    protected cookieService: CookieService,
    protected logger: NGXLogger,
    protected identityKey: string,
    protected apiURL: string,
    protected URLS: IURLS,
    protected oauth2service: OAuth2Service,
    public oauth2config: OAuth2Config,
  ) {
  }

  login(data: T) {
    return this.http
      .post(`${this.apiURL}/${this.endpointName}`, data)
      .pipe(
        shareReplay(),
        mergeMap((res) => this.establishSession(res))
      );
  }

  public establishSession(data: any) {
    this.setSession(data);
    return this.refreshUserProfile();
  }

  refreshUserProfile() {
    return this.userService.getMe()
      .pipe(
        // Check if user is not client yet
        mergeMap(
          me => {
            // If is client, return the user
            if (me.data.roles.includes(ROLES.ROLE_CLIENT) || me.data.roles.includes(ROLES.ROLE_ADMIN)) {
              return of(me);
            }

            // Else create the client and go on
            return this.clientService.add({user: me.data.id})
              .pipe(mergeMap(() => this.userService.getMe()));
          }),
        tap((res: any) => this.setProfile(res?.data)),
        tap(() => this.handleLangChange()),
        tap(() => this.setIdentity()),
        tap(() => this.sessionEstablished$$.next()),
        catchError(error => observableThrowError(error))
      );
  }

  setIdentity() {
    const user = this.getProfile();

    if (user) {
      let expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);

      this.cookieService.put(this.identityKey, user.email, {
        domain: this.URLS.baseDomain,
        expires,
        path: '/',
        secure: false,
        httpOnly: false,
      });
    }
  }

  public handleLogin(response: Observable<any>) {
    return response
      .pipe(
        tap(() => {
          const profile = this.getProfile();

          // User change!
          this.profileService.changeUserInfo(profile);
        }),
        take(1)
      );
  }

  redirectToSSO() {
    this.cleanSession();

    this.storageService.setItem('BACK_URL', this.router.url);
    this.oauth2service.authorize();
  }

  popBackUrl() {
    const url = this.storageService.getItem('BACK_URL');
    this.storageService.removeItem('BACK_URL');

    return url;
  }

  private setSession(authResult) {
    if (authResult?.token) {
      this.storageService.setItem('token', {token: authResult?.token});
    }
  }

  handleLangChange() {
    const lang = this.getProfile()?.locale;
    this.translocoService.setActiveLang(lang);
  }

  refreshToken() {
    return from(this.oauth2service.refreshToken())
      .pipe(
        tap((data) => this.setSession(data)),
        catchError((error) => observableThrowError(error))
      );
  }

  setProfile(user: User) {
    this.storageService.setItem('user', user);
  }

  getProfile(): User {
    const s = {...this.storageService.getItem('user')};
    return new User(s);
  }

  logout(): Observable<boolean> {
    const obs = new BehaviorSubject<boolean>(true);

    this.apiLogout();
    this.cleanSession();

    return obs.asObservable();
  }

  private apiLogout() {
    const url = `${this.oauth2config.server}logout?redirectTo=${this.URLS.platformURL}`;
    this.document.location.href = url;
  }

  getToken() {
    return this.storageService.getItem('token')?.token;
  }

  getRefreshToken() {
    return this.storageService.getItem('refreshToken')?.token;
  }

  private cleanSession() {
    this.storageService.removeItem('token');
    this.storageService.removeItem('refreshToken');
    this.storageService.removeItem('user');
  }

  public isLoggedIn() {
    return this.storageService.getItem('token') && this.storageService.getItem('user');
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  startIdentityCheck(unsubscribe: Subject<void>) {
    fromEvent(window, 'focus')
      .pipe(
        tap(() => this.checkIdentity()),
        takeUntil(unsubscribe)
      )
      .subscribe();
  }

  checkIdentity() {
    const realUserEmail = this.cookieService.get(this.identityKey);

    if (this.getProfile().email !== realUserEmail) {
      this.redirectToSSO();
    }
  }
}
