import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { SecurityService } from '../services/security.service';
import { CoreStoreService } from '../../core/store/core-store.service';
import { OAuth2Service } from '@rhbnb-nx-ws/services';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivateChild, CanActivate {
  constructor(
    private securityService: SecurityService,
    private coreStoreService: CoreStoreService,
    private oauth2service: OAuth2Service,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.updateState();
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const code = route.queryParamMap.get('code');

    if (!code) {
      return true;
    }

    return from(this.oauth2service.getToken()).pipe(
      mergeMap((res) => {
        if (res && this.securityService.isLoggedOut()) {
          return this.securityService
            .establishSession({ token: res.accessToken })
            .pipe(
              tap(() => this.updateState()),
              map(() => true)
            );
        }

        return of(true);
      }),
      tap(() => {
        const back = this.securityService.popBackUrl();

        if (back) {
          this.router.navigateByUrl(back);
        } else {
          this.router.navigate(['/']);
        }
      })
    );
  }

  updateState(): void {
    if (this.securityService.isLoggedIn()) {
      const user = this.securityService.getProfile();
      this.coreStoreService.setUser(user);
    }
  }
}
