import { AfterViewInit, Component, DoCheck, NgZone, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import { CurrencyKeyType } from '@rhbnb-nx-ws/domain';
import { SwUpdate } from '@angular/service-worker';
import {
  filter,
  map,
  mergeMap,
  retry,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';
import { CompleteProfileDialogService } from '@rhbnb-nx-ws/ui-share-components';
import { Subscription, of } from 'rxjs';

import { LoadIconsService } from '../shared/services/load-icons.service';
import { WithUnsubscribe } from '../shared/classes/with-unsubscribe';
import { SecurityService } from '../shared/services/security.service';
import { CoreStoreService } from './store/core-store.service';
import { CurrencyService } from '../shared/services/currency.service';
import { LanguageService } from '../shared/services/language.service';
import { GeodataApiService } from '../shared/services/geodata-api.service';
import { TagMetadataService } from '../shared/services/tag-metadata.service';
import { UserApiService } from '../shared/services/user-api.service';

@Component({
  selector: 'rhbnb-root',
  template: ` <router-outlet></router-outlet> `,
  styleUrls: ['./app.component.sass'],
})
export class AppComponent extends WithUnsubscribe() implements DoCheck, OnInit, AfterViewInit {
  count = 0;

  constructor(
    private loadIconService: LoadIconsService,
    private logger: NGXLogger,
    private securityService: SecurityService,
    private translocoService: TranslocoService,
    private titleService: Title,
    private swUpdate: SwUpdate,
    private coreStoreService: CoreStoreService,
    private currencyService: CurrencyService,
    private languageService: LanguageService,
    private geodataApiService: GeodataApiService,
    private tagMetadataService: TagMetadataService,
    private zone: NgZone,
    private completeProfileDialogService: CompleteProfileDialogService,
    private userApiService: UserApiService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.loadIconService.loadAll();
      this.securityService.startIdentityCheck(this.unsubscribe$);

      this.zone.run(() => this.handleUpdate());
      this.handleCurrencyChange();

      this.zone.run(() => {
        this.handleLangChange();

        this.tagMetadataService.listenForTagsChanges();
        this.tagMetadataService.setDefaultTags();
      });
    });

    this.handleProfileComplete();
  }

  ngDoCheck(): void {
    this.logger.debug(`DoCheck On Core: ${++this.count}`);
  }

  handleProfileComplete() {
    const sub: Subscription = this.coreStoreService
      .getUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((u) => !!u),
        mergeMap((u) => {
          return !u.mobile
            ? this.completeProfileDialogService.open(
                'core.complete_profile_title',
                u
              )
            : of(false);
        }),
        mergeMap(data => this.userApiService.patchMe({ ...data })),
        retry(10),
        tap(res => {
          const user = res.data;

          this.securityService.setProfile(user);
          this.coreStoreService.setUser(user);

          sub?.unsubscribe();
        }),
      )
      .subscribe();
  }

  /**
   * Handle SW Update
   */
  handleUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((e) => e.type === 'VERSION_READY'),
          takeUntil(this.unsubscribe$),
          map(() => this.coreStoreService.setUpdateAvailable(true))
        )
        .subscribe();
    }
  }

  handleLangChange() {
    this.translocoService
      .selectTranslate('core.title')
      .pipe(
        tap((t) => this.titleService.setTitle(t)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.coreStoreService
      .getCurrentLang()
      .pipe(
        tap((lang) => this.translocoService.setActiveLang(lang)),
        tap((lang) => this.languageService.storeLang(lang)),
        tap((lang) => moment.locale(lang)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  handleCurrencyChange() {
    const storageCurrency = this.currencyService.loadCurrency();

    of(storageCurrency)
      .pipe(
        switchMap((currency) =>
          currency
            ? of(currency)
            : this.geodataApiService.detectCurrency().pipe(map((r) => r.data))
        ),
        tap((currency) =>
          this.coreStoreService.setCurrentCurrency(currency as CurrencyKeyType)
        ),
        tap((currency) => {
          if (!storageCurrency) {
            this.currencyService.storeCurrency(currency);
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
