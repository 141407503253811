import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { SecurityService } from '../services/security.service';
import { NO_AUTH_HEADER } from '@rhbnb-nx-ws/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private securityService: SecurityService
  ) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<any> {

    const canAddToken = () => {
      if (req.headers.has(NO_AUTH_HEADER)) {
        req = req.clone({
          headers: req.headers.delete(NO_AUTH_HEADER)
        });
        return false;
      }

      if (req.url.includes('login_check')) {
        return false;
      }

      if (req.url.includes('renew-token')) {
        return false;
      }

      if (!this.securityService.getToken()) {
        return false;
      }

      return true;
    }

    if (canAddToken()) {
      req = this.addToken(req, this.securityService.getToken());
    }

    return next.handle(req)
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !req.url.includes('renew-token')) {
          return this.handleExpiredTokenError(req, next);
        } else {
          return throwError(error);
        }
      }));
  }

  addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  private handleExpiredTokenError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {

      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.securityService.refreshToken()
        .pipe(
          switchMap((data: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(data?.accessToken);

            return next.handle(this.addToken(request, data?.accessToken));
          }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        }));
    }
  }
}
