import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private securityService: SecurityService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Get next URL from route, add query params, and go!
    const backUrl = `${this.document?.location?.origin}${state.url}`

    if (this.securityService.isLoggedOut()) {
      this.securityService.redirectToSSO(backUrl);
      return false;
    }

    return true;
  }
}
