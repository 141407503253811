import { Inject, Injectable } from '@angular/core';
import { IURLS, URLS } from '@rhbnb-nx-ws/global-tokens';

@Injectable()
export class FilterImagePipeService {

  constructor(
    @Inject(URLS) private urls: IURLS,
  ) {
  }

  filter(url: any, sizes: {w: number, h: number}): any {
    const { w, h } = sizes;
    const _sizes = (w !== undefined && h !== undefined) ? `${w}x${h}/` : '';

    return `${this.urls.imageFilterServiceUrl}/unsafe/${_sizes}filters:format(webp)/${url}`;
  }
}
