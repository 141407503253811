import { Injectable } from '@angular/core';

const prodURL = ['https://rentalho.com', 'https://admin.rentalho.com'];
const qaURL = ['https://pltest.rentalho.com', 'https://account.rentalho.com'];
const devURL = [
  'https://platform.docker.vm:4201',
  'https://admin.docker.vm:4202',
];

const domains = {
  'rentalho.com': prodURL,
  'www.rentalho.com': prodURL,
  'admin.rentalho.com': prodURL,
  'pltest.rentalho.com': qaURL,
  'account.rentalho.com': qaURL,
  'platform.docker.vm:4201': devURL,
  'admin.docker.vm:4202': devURL,
};

@Injectable({
  providedIn: 'root',
})
export class UrlUtilService {
  constructor() {}

  getAppsURL() {
    const domain = this.getDomain();
    const [platform, admin] = domains[domain];

    return { platform, admin };
  }

  getDomain() {
    const href = window?.location?.href || '';
    return this.getHostName(href);
  }

  getBaseDomain(removePort = false) {
    const href = window?.location?.href || '';
    let domain = this.getHostName(href);

    const parts = domain.split('.');

    if (parts.length > 2) {
      domain =  parts.slice(1).join('.');
    }

    if (removePort) {
      domain = domain.split(':')[0];
    }

    return domain;
  }

  getUrlToAdmin(path = '', addProtocol = false) {
    const { admin } = this.getAppsURL();

    return `${this.getHostName(admin, addProtocol)}/${path}`;
  }

  getUrlToPlatform(path = '', addProtocol = false) {
    const { platform } = this.getAppsURL();
    return `${this.getHostName(platform, addProtocol)}/${path}`;
  }

  getUrlInCurrentDomain(path = '', addProtocol = false) {
    const currentURL = window?.location?.href || '';
    const add = path.length > 0 ? `/${path}` : '';
    return `${this.getHostName(currentURL, addProtocol)}${add}`;
  }

  getHostName(url: string, addProtocol = false): string | null {
    try {
      const urlObj = new URL(url);
      const protocol = urlObj.protocol;
      const hostname = urlObj.hostname;
      const port = urlObj.port ? ':' + urlObj.port : '';

      return `${addProtocol ? protocol + '//' : ''}${hostname}${port}`;
    } catch (e) {
      return null;
    }
  }

  getParams(url: string): string[] | null {
    try {
      return new URL(url)?.pathname?.split('/');
    } catch (e) {
      return null;
    }
  }

  paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }
}
